import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { DsHeaderModule, DsHeaderTagConfiguration, DsNavigationBarModule, DsNavigationItem } from '@bmw-ds/components';
import { environment } from '@bmw-spp/bmw-spp-frontend-common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    DsHeaderModule,
    DsNavigationBarModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Transportaufträge';

  //Tag with environment for header
  environmentTagConfig: Partial<DsHeaderTagConfiguration> = { label: environment.environmentName };
  userInfo = {
    username: 'John Doe',
    department: 'Dept 1',
    profileImg: 'https://www.gravatar.com/avatar/?d=identicon'
  };

  //Navigation configuration
  navigationItems: DsNavigationItem[] = [
    {
      label: 'Transportaufträge',
      routerLink: '/transportorder',
    },
  ];
}
