import { Component, computed, OnInit, signal } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry, GridApi, ColDef, GridReadyEvent, SideBarDef } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { TransportOrderService } from '../services/transport-order/transport-order.service';
import { TransportOrder } from '../services/transport-order/transport-order.model';
import { DsBoxModule, DsButtonModule, DsFormsModule, DsModalModule, DsToastService, SelectListOption } from '@bmw-ds/components';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VehiclesService } from '../services/vehicles/vehicles.service';
import { Vehicle } from '@bmw-spp/bmw-spp-frontend-common';
import { OrderVehicleTrackable } from '../services/transport-order/order-vehicle-trackable.model';

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule, ColumnsToolPanelModule, FiltersToolPanelModule, StatusBarModule, MenuModule, SetFilterModule]);

@Component({
  selector: 'app-transport-order',
  standalone: true,
  imports: [
    AgGridAngular,
    CommonModule,
    DsBoxModule,
    DsButtonModule,
    DsFormsModule,
    DsModalModule,
    FormsModule,
  ],
  templateUrl: './transport-order.component.html',
  styleUrl: './transport-order.component.scss'
})
export class TransportOrderComponent implements OnInit {
  public gridApi!: GridApi;
  public showTransportOrderDialog = false;
  localeText = AG_GRID_LOCALE_DE;

  // Form model
  vehicle: Vehicle | undefined = undefined;
  sourceLocation = '';
  targetLocation = '';
  serviceProvider = '';
  duration = 0;
  comment = '';

  columns = signal<ColDef<TransportOrder>[]>([
    {
      field: 'id',
      headerName: 'ID',
      filter: 'agTextColumnFilter',
      sortable: false,
      valueGetter: (params) => {
        if (params.data?.id) {
          return params.data.id;
        } else {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          }); 
        }
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      field: 'transportTrackable.licensePlate',
      headerName: 'Fahrzeug',
      sortable: false,
      valueGetter: (params) => {
        const transportTrackable = params.data?.transportTrackable as OrderVehicleTrackable | undefined;
        const vehicle = transportTrackable?.vehicle as Vehicle | undefined;
        return vehicle && vehicle['vehicleLicensePlate' as keyof Vehicle] ? vehicle['vehicleLicensePlate' as keyof Vehicle] : `AC - SP 1234 - ${params.node?.rowIndex}`;
      }
    },
    {
      headerName: 'Leitzahl',
      sortable: false,
      valueGetter: (params) => {
        return `Leitzahl ${params.node?.rowIndex}`;
      },
    },
    {
      field: 'sourceLocation',
      headerName: 'Start',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      field: 'destinationLocation',
      headerName: 'Ziel',
      filter: 'agTextColumnFilter',
      sortable: false,
    },
    {
      headerName: 'Standort',
      sortable: false,
      valueGetter: (params) => {
        return `Standort ${params.node?.rowIndex}`;
      },
    },
  ]);
  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: false,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      }
    ]
  };

  public computedTransportOrders = computed(() => {
    const to = this.transportOrderService.data();
    return to;
  });

  public computedVehicles = computed(() => {
    const vehicles = this.vehicleService.data();
    return vehicles.map((vehicle: Vehicle) => {
      return {
        id: vehicle.licensePlate!,
        label: vehicle.licensePlate!,
      }
    });
  });

  constructor(private transportOrderService: TransportOrderService,
    private vehicleService: VehiclesService,
    private toastService: DsToastService
  ) { }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  ngOnInit() {
    try {
      this.transportOrderService.loadAll();
      this.vehicleService.loadAll();
    } catch {
      this.toastService.pushToast({
        id: 'transport-order-error',
        tone: 'critical',
        toastText: 'Fehler beim Laden. Bitte versuchen Sie es später erneut.'
      });
    }
  }
  
  public toggleCreateTransportOrderDialog() {
    this.showTransportOrderDialog = !this.showTransportOrderDialog;
  }

  public handleVehicleSelection(item: SelectListOption | SelectListOption[] | null) {
    if (item && 'id' in item) {
      const foundVehicle = this.vehicleService.data().find((vehicle: Vehicle) => vehicle.licensePlate === item.id);
      if (foundVehicle) {
        this.vehicle = foundVehicle;
      }
    }
  }

  public handleSourceLocationSelection(item: SelectListOption | SelectListOption[] | null) {
    if (item && 'id' in item) {
      this.sourceLocation = item.id;
    }
  }

  public handleTargetLocationSelection(item: SelectListOption | SelectListOption[] | null) {
    if (item && 'id' in item) {
      this.targetLocation = item.id;
    }
  }

  async onSubmit() {
    try {
      const startTs = new Date();
      const targetTs = new Date();
      targetTs.setMinutes(targetTs.getMinutes() + this.duration);
      // const orderTrackable: OrderVehicleTrackable = {
      //   vehicle: this.vehicle!,
      //   id: this.vehicle!.id,
      //   object: this.vehicle!.object,
      //   omloxId: this.vehicle!.omloxId,
      //   omloxSyncTs: this.vehicle!.omloxSyncTs,
      //   properties: this.vehicle!.properties,
      //   siteAlias: this.vehicle!.siteAlias,
      //   siteName: this.vehicle!.siteName,
      // };
      await this.transportOrderService.upsertTransportOrder({
        id: '',
        transportTrackable: this.vehicle!,
        tenantAlias: '',
        reporter: '',
        assignee: '',
        status: 'OPEN',
        sourceLocation: this.sourceLocation,
        sourceFence: '',
        destinationLocation: this.targetLocation,
        destinationFence: '',
        startTs: startTs.toUTCString(),
        targetTs: targetTs.toUTCString(),
        omloxSyncTs: new Date().toUTCString(),
    });
    this.showTransportOrderDialog = false;
    this.toastService.pushToast({
      id: 'transport-order-success',
      tone: 'positive',
      toastText: 'Transportauftrag erfolgreich erstellt.'
    });
    } catch {
      this.toastService.pushToast({
        id: 'transport-order-error',
        tone: 'critical',
        toastText: 'Fehler beim Speichern. Bitte versuchen Sie es später erneut.'
      });
    }
  }
}
