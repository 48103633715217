import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { DsImprintModule } from '@bmw-ds/components';
import { environment } from '../environments/environment';
import { ENVIRONMENT } from '@bmw-spp/bmw-spp-frontend-common';
import { provideHttpClient } from '@angular/common/http';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(),
    importProvidersFrom(
      DsImprintModule.forRoot({
        phone: '5555555',
        electronicContact: 'change-me@bmwgroup.com'
      }),
    ),
    { provide: ENVIRONMENT, useValue: environment },
    ...environment.providers,
  ]
};
