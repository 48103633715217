import { BaseBackendService, Vehicle } from "@bmw-spp/bmw-spp-frontend-common";
import { environment } from "../../../environments/environment";
import { firstValueFrom } from "rxjs";
import { VehiclesService } from "./vehicles.service";

export class VehiclesBackendService extends BaseBackendService<Vehicle> implements VehiclesService {
    public async upsertTransportOrder(transportOrder: Vehicle): Promise<void> {
        const url = `${environment.apiUrl}/${this.getEndpoint()}`;
        await firstValueFrom(this.httpClient.post(url, transportOrder));
    }
    protected override getEndpoint(): string {
        return "v1/transport-order/get-vehicles";
    }

    public override async loadAll(): Promise<Vehicle[]> {
        const url = `${environment.apiUrl}/${this.getEndpoint()}`;
        const vehicles = await firstValueFrom(this.httpClient.get<Vehicle[]>(url));
        const parsedVehicles = vehicles.map(this.mapBackendToFrontend);
        this.data.set(parsedVehicles);

        return parsedVehicles;
    }

    protected override mapBackendToFrontend(item: unknown): Vehicle {
        return {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            id: (item as any).vehicle.vehicleIdentificationNumber,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            licensePlate: (item as any).vehicle.vehicleLicensePlate,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            vin: (item as any).vehicle.vehicleIdentificationNumber,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            carType: (item as any).vehicle.vehicleType,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            visitReason: (item as any).vehicle.visitReason,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            internalContact: (item as any).vehicle.internalContact.firstName + ' ' + (item as any).vehicle.internalContact.name,
        };
    }
}