import { Injectable } from "@angular/core";
import { BaseBackendService } from "@bmw-spp/bmw-spp-frontend-common";
import { TransportOrder } from "./transport-order.model";
import { TransportOrderService } from "./transport-order.service";
import { environment } from "../../../environments/environment";
import { firstValueFrom } from "rxjs";

@Injectable()
export class TransportOrderBackendService extends BaseBackendService<TransportOrder> implements TransportOrderService {
    public async upsertTransportOrder(transportOrder: TransportOrder): Promise<void> {
        const url = `${environment.apiUrl}/${this.getCreateEndpoint()}`;
        const response = await firstValueFrom(this.httpClient.post<TransportOrder>(url, transportOrder));
        this.data.set([...this.data(), response]);
    }

    protected override getEndpoint(): string {
        return "v1/transport-order/get-transport-orders";
    }

    private getCreateEndpoint(): string {
        return "v1/transport-order/create-transport-order";
    }
}