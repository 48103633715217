import { IEnvironment } from "@bmw-spp/bmw-spp-frontend-common";
import { TransportOrderService } from "../app/services/transport-order/transport-order.service";
import { TransportOrderBackendService } from "../app/services/transport-order/transport-order.backend.service";
import { VehiclesService } from "../app/services/vehicles/vehicles.service";
import { VehiclesBackendService } from "../app/services/vehicles/vehicles.backend.services";

export const environment: IEnvironment = {
    environmentName: 'Dev',
    apiUrl: 'https://tra-backend.c2-cloud.de/tra/',
    wsUrl: 'wss://spp-backend.c2-cloud.de/tra/',
    reconnect: {
        retries: 10,
        delayInSeconds: 5000,
    },
    providers: [
        { provide: TransportOrderService, useClass: TransportOrderBackendService },
        { provide: VehiclesService, useClass: VehiclesBackendService }
    ],
    authEnabled: false,
    clientId: "c2983a8f-ca78-4bd3-b923-940bd716e50a",
    issuer: "https://auth-i.bmwgroup.net:443/auth/oauth2/realms/root/realms/internetb2xmfaonly",
    secureRouteRootUrl: "https://spp-backend.c2-cloud.de/tra/",
    redirectURL: "https://spp-tra.c2-cloud.de/",
};